import React from "react"
import { Container, Row, Col } from "react-awesome-styled-grid"

import MariaSVG from "../components/mariasvg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { MariaSecondary, MariaPrimary} from "../utils/colors"



class IndexPage extends React.Component {
  render() {
    const siteTitle = "Home"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`video`, `multimedia`, `journalist`, `coder`, `digital content`, `producer`, `developer`,'portfolio']}
          description="Hi! I'm Maria Dermentzi, a Europe-based multimedia producer with robust coding, data, and video skills."
        />
        <FirstRow />
      </Layout>
    )
  }
}
const FirstRow = () => (
  <Container>
    <Row align='center' style={{marginBottom: `-1rem`, marginTop: `1rem`}}>
      <Col xs={4} md={4}>
        <h1 style={{marginBottom: `0.6rem`}}>
          Hi! I'm <span style={{color:MariaPrimary, backgroundColor:MariaSecondary, padding: `0.2rem`}}>Maria</span>.
        </h1>
        <p>I'm a Web Research Developer at King's College London.</p>
        <p>Previously, I was a video producer at Mashable. I have an MSc in Digital Humanities from KU Leuven, an MA in Digital Culture from King's College London, and a Law degree from Aristotle University.</p>
        <p>My digital skillset spans from coding to videography and I'm using it to explore new ways of telling stories in the digital realm.</p>
      </Col>
      <Col xs={4} md={4}>
        <MariaSVG />
      </Col>
    </Row>
  </Container>
)



export default IndexPage
